<template>
  <div id="borrowable-asset-detail" class="container py-5">
    <div class="header mb-3">
      <h3
        class="my-4 fw-normal"
        style="color: #017AFE"
        v-if="returnAssetBox.withInput"
      >
        {{ $t("borrow.return_asset_confirmation_title") }}
      </h3>
      <h3 class="my-4 fw-normal" v-else>
        {{ $t("borrow.borrow_return_asset") }}
      </h3>
      <div class="image-preview-container my-4">
        <img
          class="object-fit-cover border rounded w-100"
          :src="asset.image_url.url"
        />
      </div>
      <div class="my-4">
        <div>
          <span class="fw-light">{{ $t("asset.as_num") }}</span>
          &nbsp;
          <span>{{ asset.id_asset }}</span>
        </div>
        <div>
          <div>
            <span class="fw-light">{{ $t("asset.as_name") }}</span
            >&nbsp;
            <span>{{ asset.name }}</span>
          </div>
        </div>
      </div>
      <div class="text-start">
        <div class="my-2 color-primary">
          {{ $t("borrow.borrow_return_status") }}
        </div>
        <BorrowReturnStatusCard :status="asset.status" />
      </div>
    </div>

    <div class="details mb-3">
      <h4 class="fw-normal fs-5">{{ $t("borrow.borrow_return_detail") }}</h4>
      <div class="detail-list text-start">
        <div>
          <small>{{ $t("borrow.borrow_id") }}</small>
          <div>{{ asset.request_id }}</div>
        </div>
        <div>
          <small>{{ $t("borrow.borrower_name") }}</small>
          <div>{{ asset.borrower }}</div>
        </div>
        <div>
          <small>{{ $t("borrow.borrow_date") }}</small>
          <div>{{ moment(asset.borrow_date).format("DD/MM/YYYY") }}</div>
        </div>
        <div>
          <small>{{ $t("borrow.borrow_due_date") }}</small>
          <div>{{ moment(asset.end_date).format("DD/MM/YYYY") }}</div>
        </div>
        <div>
          <small>{{ $t("borrow.ref1") }}</small>
          <div>{{ asset.ref }}</div>
        </div>
        <div>
          <small>{{ $t("borrow.ref2") }}</small>
          <div>{{ asset.ref2 }}</div>
        </div>
      </div>
    </div>

    <div class="extra-detail">
      <div
        class="d-flex justify-content-between align-items-center"
        @click="handleAppendExtraDetails"
        style="cursor: pointer;"
      >
        <div>
          {{ $t("asset.as_dt") }}
        </div>
        <div>
          <font-awesome-icon
            class="collapse-icon"
            :icon="showExtraDetails ? 'minus-circle' : 'plus-circle'"
          />
        </div>
      </div>
      <ExpandHeight class="py-2">
        <div class="detail-list" v-if="showExtraDetails">
          <div>
            <small>{{ $t("asset.as_dt") }}</small>
            <div>{{ asset.detail }}</div>
          </div>
          <div>
            <small>{{ $t("asset.as_cat") }}</small>
            <div>{{ asset.category }}</div>
          </div>
          <div>
            <small>{{ $t("asset.as_sub_cat") }}</small>
            <div>{{ asset.sub_category }}</div>
          </div>
          <div>
            <small>{{ $t("asset.as_dep") }}</small>
            <p>
              {{ $t("asset.build") }}
              {{ asset.location_building ? asset.location_building : "-" }}
              {{ $t("asset.fl") }}
              {{ asset.location_floor ? asset.location_floor : "-" }}
              <br />
              {{ $t("asset.dep") }}
              {{ asset.location_department ? asset.location_department : "-" }}
              {{ $t("asset.zone") }}
              {{ asset.location_zone ? asset.location_zone : "-" }}
            </p>
          </div>
        </div>
      </ExpandHeight>
    </div>

    <div id="send-confirmation-box" class="py-3" v-show="returnAssetBox.show">
      <div class="mb-3">{{ $t("borrow.send_confirmation") }}</div>
      <template v-if="returnAssetBox.withInput">
        <div class="text-start">
          <small style="color: #7C7C7C">{{ $t("asset.as_stat") }}</small>
          <div class="mb-3">
            <p
              class="sub-label require-red clear-pad-mar status_label"
              v-if="asset.is_lost === 'lost'"
            >
              {{ $t("count.as_lost") }}
            </p>
            <p
              class="sub-label require-red clear-pad-mar status_label"
              v-else-if="!asset.qrcode"
            >
              {{ $t("count.not_reg") }}
            </p>
            <p
              class="sub-label require-red clear-pad-mar status_label"
              v-else-if="asset.status == null && asset.damaged_status == null"
            >
              {{ $t("count.not_count") }}
            </p>
            <!-- <p v-else-if="asset.status" class="sub-label status_label">
              {{ assetStatusTranslate(asset.status) }}
              <span v-if="asset.status === 'ชำรุด'">:</span>
              <span
                v-if="asset.damaged_status === 'รอการประเมิน'"
                class="require-red"
              >
                {{ assetStatusTranslate(asset.damaged_status) }}</span
              >
              <span v-else>
                {{ assetStatusTranslate(asset.damaged_status) }}</span
              >
            </p> -->
            <p v-else>
              {{ $t("asset.available") }}
            </p>
          </div>
          <ButtonSelector
            class="mb-3"
            :options="returnAssetOptions"
            @select="handleButtonSelectorSelected"
          />
          <div class="mb-3">
            <small class="d-block mb-2">
              {{ $t("borrow.return_note") }}
            </small>
            <input
              v-model="return_note"
              name="return_note"
              type="text"
              placeholder="-"
              class="form-control"
              style="padding: 0.75rem 0.75rem 0.75rem 30px; background: #E5E7E7 !important;"
            />
          </div>
          <div>
            <button
              class="d-block mx-auto"
              style="background-color: #007afe;"
              @click="
                updateBorrowReturnAssetStatus(selectedStatusValue, return_note)
              "
            >
              {{ $t("btn.conf") }}
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex justify-content-center">
          <button class="mx-3" style="background-color: #7c7c7c">
            {{ $t("btn.canc") }}
          </button>
          <button
            class="mx-3"
            style="background-color: #007afe"
            @click="
              () => updateBorrowReturnAssetStatus(BorrowReturnStatuses.USING)
            "
          >
            {{ $t("btn.conf") }}
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import BorrowReturnStatusCard from "../../components/BorrowReturn/BorrowReturnStatusCard.vue";
import ExpandHeight from "../../components/Animation/expandHeight.vue";
import ButtonSelector from "../../components/ButtonSelector.vue";
import { BorrowReturnStatuses } from "../../constants/borrow-return/BorrowReturnStatuses";
import axios from "axios";
import { authHeader } from "../../store/actions";
import { borrowUrl } from "@/util/backend";

export default {
  name: "BorrowableAssetDetail",
  components: {
    BorrowReturnStatusCard,
    ButtonSelector,
    ExpandHeight,
  },
  data() {
    return {
      return_note: null,
      asset: {
        id_asset: null,
        qrcode: null,
        image_url: {
          date: null,
          url: null,
        },
        name: null,
        status: null,
        request_id: null,
        borrower: null,
        borrow_date: null,
        end_date: null,
        ref: null,
        ref2: null,
        detail: null,
        category: null,
        sub_category: null,
        location_building: null,
        location_floor: null,
        location_department: null,
        location_zone: null,
        borrow_return_request: null,
      },

      showExtraDetails: false,
      selectedStatusValue: null,
    };
  },
  computed: {
    BorrowReturnStatuses: () => BorrowReturnStatuses,
    returnAssetBox() {
      const options = {
        show: false,
        withInput: false,
      };
      // Show return asset confirmation box if asset is in to send, using, to return or overdue status
      if (
        [
          BorrowReturnStatuses.TO_SEND,
          BorrowReturnStatuses.USING,
          BorrowReturnStatuses.TO_RETURN,
          BorrowReturnStatuses.OVERDUE,
        ].includes(this.asset.status)
      ) {
        options.show = true;
      }

      // Show input box if asset is in using, to return or overdue status
      if (
        [
          BorrowReturnStatuses.USING,
          BorrowReturnStatuses.TO_RETURN,
          BorrowReturnStatuses.OVERDUE,
        ].includes(this.asset.status)
      ) {
        options.withInput = true;
      }

      return options;
    },
    returnAssetOptions() {
      switch (this.asset.status) {
        case BorrowReturnStatuses.USING:
          return [
            {
              label: this.$t("borrow.normal_returned_status"),
              value: BorrowReturnStatuses.RETURNED,
            },
            //TODO - เปิดเมื่อแจ้งซ่อมเสร็จแล้ว เฉพาะคนที่ซื้อ
            // {
            //   label: this.$t("borrow.broken_returned_status"),
            //   value: BorrowReturnStatuses.RETURNED_DAMAGED,
            // },
          ];
        case BorrowReturnStatuses.TO_RETURN:
          return [
            {
              label: this.$t("borrow.returned_status"),
              value: BorrowReturnStatuses.RETURNED,
            },
            //TODO - เปิดเมื่อแจ้งซ่อมเสร็จแล้ว เฉพาะคนที่ซื้อ
            // {
            //   label: this.$t("borrow.broken_status"),
            //   value: BorrowReturnStatuses.RETURNED_DAMAGED,
            // },
          ];
        case BorrowReturnStatuses.OVERDUE:
          return [
            {
              label: this.$t("borrow.overdue_returned_status"),
              value: BorrowReturnStatuses.RETURNED_OVERDUE,
            },
            //TODO - เปิดเมื่อแจ้งซ่อมเสร็จแล้ว เฉพาะคนที่ซื้อ
            // {
            //   label: this.$t("borrow.broken_status"),
            //   value: BorrowReturnStatuses.RETURNED_DAMAGED_OVERDUE,
            // },
          ];
        default:
          return [];
      }
    },
  },
  methods: {
    moment,
    async updateBorrowReturnAssetStatus(status, return_note = null) {
      try {
        let payload =
          status === "LOST"
            ? {
                status: status,
                note_lost: return_note,
              }
            : {
                status,
                return_note,
              };
        await axios.post(
          `${borrowUrl()}asset/id/${this.asset.id}/status`,
          payload,
          authHeader()
        );
        await Swal.fire({
          icon: "success",
          title: this.$t("dialog.success"),
          showConfirmButton: false,
          timer: 3000,
        });
        this.$router.back();
      } catch (err) {
        console.error(err);
      }
    },
    handleAppendExtraDetails() {
      this.showExtraDetails = !this.showExtraDetails;
    },
    handleButtonSelectorSelected(value) {
      this.selectedStatusValue = value;
    },
    assetStatusTranslate(text) {
      switch (text) {
        case "ใช้งานได้":
          return this.$t("count.inuse");
        case "ไม่ได้ใช้งาน":
          return this.$t("count.not_inuse");
        case "ชำรุด":
          return this.$t("count.defect");
        case "อยู่ระหว่างการซ่อม":
          return this.$t("count.repairing");
        case "ซ่อมได้":
          return this.$t("count.repar");
        case "ซ่อมไม่ได้":
          return this.$t("count.irrepar");
        case "รอการประเมิน":
          return this.$t("count.wait_as");
        case "กำลังซ่อม":
          return this.$t("maintenance.fixing");
        case "ซ่อมแล้ว":
          return this.$t("maintenance.fixed");
        default:
          return "";
      }
    },
    setAsset(data) {
      this.asset = {
        id: data.id,
        id_asset: data.asset.id_fixed_asset,
        image_url: {
          date: data.asset.image_url.date,
          url: data.asset.image_url.url,
        },
        name: data.asset.name,
        status: data.status,
        request_id: data.borrow_return_request.fixed_request_id,
        borrower: data.borrow_return_request.user.name,
        borrow_date: data.start_datetime,
        qrcode: data.asset.qrcode,
        end_date: data.end_datetime,
        ref: data.asset.ref || "-",
        ref2: data.asset.ref2 || "-",
        detail: data.asset.detail || "-",
        category: data.asset.category?.name || "-",
        sub_category: data.asset.sub_category?.name || "-",
        location_building: data.asset.location_building?.name || "-",
        location_floor: data.asset.location_floor?.name || "-",
        location_department: data.asset.location_department?.name || "-",
        location_zone: data.asset.location_zone?.name || "-",
        borrow_return_request: data.borrow_return_request,
      };
    },
    async loadAsset() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      const qrAssetId = this.$route.params.qrAssetId;
      try {
        const res = await axios.get(
          `${borrowUrl()}asset/qr/id/${qrAssetId}/company/id/${id_company}`,
          authHeader()
        );
        const data = res.data;

        // Wait for backend add bad request status code
        if (Object.keys(data).length <= 0) {
          throw new Error("Data not found");
        }

        this.setAsset(data);
      } catch (err) {
        if (err.response) {
          if (err.response.data.error === "ERR_ASSET_NOT_REGISTERED") {
            const answer = await Swal.fire({
              title: this.$t(
                "borrow.scan_qr_error.ERR_ASSET_NOT_REGISTERED.head"
              ),
              text: this.$t(
                "borrow.scan_qr_error.ERR_ASSET_NOT_REGISTERED.desc"
              ),
              imageUrl:
                "https://api.verscan.com/download/sweet-alert-alert.svg",
              confirmButtonText: this.$t("registeritem.regist"),
              cancelButtonText: this.$t("btn.close"),
              reverseButtons: true,
              showCancelButton: true,
              imageHeight: 48,
              imageWidth: 48,
            });
            if (answer.isConfirmed) {
              this.$router.replace(
                `/registerItem/${encodeURIComponent(qrAssetId)}`
              );
            } else {
              this.$router.back();
            }
          } else if (err.response.data.error === "ERR_ASSET_ID_NOT_FOUND") {
            const answer = await Swal.fire({
              title: this.$t(
                "borrow.scan_qr_error.ERR_ASSET_ID_NOT_FOUND.head"
              ),
              text: this.$t("borrow.scan_qr_error.ERR_ASSET_ID_NOT_FOUND.desc"),
              imageUrl:
                "https://api.verscan.com/download/sweet-alert-alert.svg",
              confirmButtonText: this.$t("borrow.add_borrow_return"),
              cancelButtonText: this.$t("btn.close"),
              reverseButtons: true,
              showCancelButton: true,
              imageHeight: 48,
              imageWidth: 48,
            });
            if (answer.isConfirmed) {
              this.$router.replace({ name: "add-borrow" });
            } else {
              this.$router.back();
            }
          } else if (err.response.data.error === "ERR_ASSET_ID_NOT_BOOKED") {
            await Swal.fire({
              title: this.$t(
                "borrow.scan_qr_error.ERR_ASSET_ID_NOT_BOOKED.head"
              ),
              text: this.$t(
                "borrow.scan_qr_error.ERR_ASSET_ID_NOT_BOOKED.desc"
              ),
              imageUrl:
                "https://api.verscan.com/download/sweet-alert-alert.svg",
              confirmButtonText: this.$t("btn.close"),
              imageHeight: 48,
              imageWidth: 48,
            });
            this.$router.back();
          } else {
            await Swal.fire({
              title: this.$t("dialog.wrong_qr"),
              text: err.response.data.description,
              timer: 3000,
              imageUrl:
                "https://api.verscan.com/download/sweet-alert-alert.svg",
              confirmButtonText: this.$t("btn.close"),
              imageHeight: 48,
              imageWidth: 48,
            });
            this.$router.back();
          }
        } else {
          await Swal.fire({
            title: this.$t("dialog.wrong_qr"),
            text: this.$t("dialog.wrong_qr_dt"),
            timer: 3000,
            imageUrl: "https://api.verscan.com/download/sweet-alert-alert.svg",
            confirmButtonText: this.$t("btn.close"),
            imageHeight: 48,
            imageWidth: 48,
          });
          this.$router.back();
        }
      }
    },
  },
  created() {
    this.loadAsset();
  },
};
</script>

<style scoped>
#borrowable-asset-detail {
  max-width: 600px;
  margin: 0 auto;
}
.image-preview-container {
  margin: 0 auto;
  max-width: 126px;
  max-height: 126px;
  overflow: hidden;
}

.header,
.extra-detail,
.details {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 1em;
}

.detail-list {
  text-align: left;
}

.detail-list small {
  color: #7c7c7c;
}

.detail-list div {
  margin-bottom: 0.7em;
}

.collapse-icon {
  color: #007afe;
}

#send-confirmation-box button {
  width: 155px;
  padding: 12px 5px;
  border: none;
  color: #fff;
  border-radius: 8px;
}

.btn-outline-unactive {
  background: #fff;
  color: #007afe !important;
  border: 1px solid #007afe !important;
}
</style>
